<template>
  <v-simple-table class="border" fixed-header>
    <template v-slot:default>
      <thead>
        <tr class="text-center">
          <th class="text-center" v-for="(head, index) in heads" :key="index">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <slot />
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "SimpleTable",
  props: {
    heads: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
