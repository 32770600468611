<template>
  <div>
    <v-card
      v-if="!loading"
      :color="result != null ? 'transparent' : ''"
      :elevation="result != null ? 0 : 5"
    >
      <div v-if="result == null">
        <v-toolbar color="#1a1a27" flat dark height="80px">
          <router-link :to="{ name: 'user-offers-list' }" class="mr-3">
            <v-btn icon> <v-icon>mdi-arrow-left</v-icon></v-btn>
          </router-link>
          <v-spacer></v-spacer>
          <v-toolbar-title class="ml-3">
            <h2 class="mb-0">Onay Bekleyen İşlemlerim</h2>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <KTTopbar></KTTopbar>
        </v-toolbar>
        <div class="pa-5">
          <v-row class="flex-row">
            <v-col cols="12" md="6">
              <div class="d-flex justify-content-center mb-3 text-h6">
                <span color="#1a1a27" class="mr-2"
                  ><b>{{
                    paymentPlan.offer.production.display_name ??
                    paymentPlan.offer.third_base_class_choice_name
                  }}</b></span
                >
                Ürünü Eğitim İçeriği
              </div>
              <div>
                <SimpleTable :heads="trainingsHeader">
                  <tr v-for="(item, index) in educations" :key="index">
                    <td style="text-align: center">{{ item.name }}</td>
                    <td
                      :class="
                        item.discounted_price >= item.price
                          ? ''
                          : 'text-decoration-line-through'
                      "
                      :style="
                        item.discounted_price >= item.price ? '' : 'color: red'
                      "
                      style="text-align: center"
                    >
                      {{
                        disableDiscountedPriceColumn
                          ? formatPrice(item.discounted_price)
                          : formatPrice(item.price)
                      }}
                    </td>
                    <td
                      v-if="!disableDiscountedPriceColumn"
                      style="text-align: center"
                    >
                      {{ formatPrice(item.discounted_price) }}
                    </td>
                  </tr>
                </SimpleTable>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="d-flex justify-content-center mb-3 text-h6">
                <span>Ödeme Planı</span>
              </div>
              <SimpleTable :heads="paymentPlanHeader" height="528">
                <tr v-for="(item, index) in payments" :key="index">
                  <td style="text-align: center">
                    <b>{{ item.month }}. Taksit</b>
                  </td>
                  <td style="text-align: center">
                    {{ formatPrice(item.price) }}
                  </td>
                  <td style="text-align: center">{{ item.date }}</td>
                </tr>
              </SimpleTable>
              <v-row class="d-flex justify-content-end mt-3 mr-1">
                <span
                  ><b>Toplam Ödenecek Tutar: </b>
                  {{ formatPrice(parseFloat(paymentPlan.total_payment)) }}</span
                >
              </v-row>
              <v-row class="d-flex justify-content-end mt-3 mr-1">
                <v-checkbox v-model="isAgreed" color="primary" hide-details>
                  <template v-slot:label>
                    <span>
                      <a
                        class="text-decoration-none text-info"
                        :href="preInformationUrl"
                        @click.stop
                        v-on="on"
                        target="_blank"
                        >Ön Bilgilendirme Formu</a
                      >
                      'nu okudum,
                      <a
                        class="text-decoration-none text-info"
                        :href="agreementUrl"
                        @click.stop
                        v-on="on"
                        target="_blank"
                        >Mesafeli Satış Sözleşmesi</a
                      >
                      'ni kabul ediyorum.
                    </span>
                  </template>
                </v-checkbox>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="isChoiceAvailable()">
              <div class="d-flex justify-content-end">
                <v-btn elevation="0" color="error" @click="showRejectedModal">
                  İşlemi Reddet
                </v-btn>
                <v-btn
                  elevation="0"
                  color="primary"
                  class="ml-4"
                  :disabled="!isAgreed"
                  @click="() => offerAction('closed')"
                >
                  İşlemi Onayla
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" v-else>
              <div class="d-flex justify-content-end">
                <v-alert
                  border="right"
                  colored-border
                  type="error"
                  elevation="2"
                >
                  Bu işlem için daha önce bir seçim yapmışsınız.
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-sheet
          elevation="12"
          max-width="600"
          rounded="lg"
          width="100%"
          class="pa-4 text-center mx-auto"
        >
          <v-icon
            v-if="result === 'closed'"
            class="mb-5"
            color="success"
            size="112"
            >mdi-check-circle</v-icon
          >
          <v-icon v-else class="mb-5" color="error" size="112">
            mdi-alert-circle
          </v-icon>

          <h2 class="text-h5 mb-6">
            {{ result == "closed" ? "İşlem Onaylandı" : "İşlem Reddedildi" }}
          </h2>

          <p
            v-if="result === 'closed'"
            class="mb-4 text-medium-emphasis text-body-2"
          >
            İşleminizi onayladığınız için teşekkür ederiz. Kariyer
            yolculuğunuzda sizinle beraber yürümekten büyük mutluluk duyacağız.
            Eğer görmek istediğiniz başka bir işleminiz varsa,
            <a
              href="https://crm.siliconmadeacademy.com/user/offers"
              class="text-decoration-none text-info"
              text
              >Onay Bekleyen İşlemlerim sayfasına geri dönebilirsiniz.</a
            >

            <br />
            <br />

            Eğer yoksa, her şey tamamlandı!
          </p>

          <p v-else class="mb-4 text-medium-emphasis text-body-2">
            İşleminizi reddettiğiniz için üzgünüz. Eğer işlemi reddetme
            sebebiniz hakkında bizimle iletişime geçmek isterseniz,
            <a
              href="https://siliconmadeacademy.com/?contact_us=true"
              target="_blank"
              class="text-decoration-none text-info"
              >İletişim sayfasına</a
            >
            geri dönebilirsiniz.
          </p>

          <v-divider v-if="result === 'closed'" class="mb-4"></v-divider>

          <div v-if="result === 'closed'" class="text-center">
            <v-btn text :to="{ name: 'user-payments-list' }" color="success">
              Ödemelerim Listesine Git
            </v-btn>
          </div>
        </v-sheet>
      </div>
    </v-card>
    <div
      v-else-if="loading"
      style="height: 250px"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
        width="7"
      ></v-progress-circular>
      <h3 class="mt-5">Lütfen bekleyiniz...</h3>
    </div>
  </div>
</template>

<script>
import AxiosInstance from "@/core/services/axios.service";
import SimpleTable from "@/view/content/components/SimpleTable";
import { currencyMask } from "@/core/helper";
import { formatPrice } from "@/core/helper.js";
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";

export default {
  components: { SimpleTable, KTTopbar },
  data() {
    return {
      result: null,
      paymentPlanHeader: ["Ay", "Taksit Tutarı", "Ödeme Tarihi"],
      paymentPlan: {},
      payments: [],
      educations: [],
      isAgreed: false,
      disableDiscountedPriceColumn: false,
      loading: true,
    };
  },
  async created() {
    this.paymentPlan = await this.getPaymentPlan();
    this.loading = false;
  },
  methods: {
    currencyMask,
    formatPrice,
    getPaymentPlan() {
      return new Promise((resolve, reject) => {
        AxiosInstance.get(`/payment-plans/${this.paymentPlanId()}`)
          .then(({ data }) => {
            this.payments = this.createPaymentPlan(data);
            this.educations = this.createEducations(data.offer.educations);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createPaymentPlan(data) {
      const paymentPlanWithInstallments = [];

      paymentPlanWithInstallments.push({
        month: 1,
        date: this.$moment(data.first_installment_date).format("DD/MM/YYYY"),
        price: parseFloat(data.per_installment_payment),
      });

      for (let i = 1; i <= data.installment_count - 1; i++) {
        paymentPlanWithInstallments.push({
          month: i + 1,
          price: parseFloat(data.per_installment_payment),
          date: this.createInstallmentDueDate(data.installment_start_date, i),
        });
      }

      return paymentPlanWithInstallments;
    },
    createInstallmentDueDate(date, index) {
      let installmentDate = this.$moment(date);
      if (index === 1) {
        return installmentDate.format("DD/MM/YYYY");
      }
      installmentDate = installmentDate
        .add(index, "months")
        .date(
          Math.min(
            this.$moment(installmentDate).date(),
            installmentDate.daysInMonth()
          )
        );
      return installmentDate.format("DD/MM/YYYY");
    },
    createEducations(data) {
      const educations = [];
      data.forEach((item) => {
        const discountedPrice = item.pivot.new_discounted_price
          ? parseFloat(item.pivot.new_discounted_price)
          : parseFloat(item.pivot.discounted_price);
        if (discountedPrice >= item.price) {
          this.disableDiscountedPriceColumn = true;
        }
        educations.push({
          name: item.name,
          price: item.price,
          discounted_price: discountedPrice,
        });
      });
      return educations;
    },
    offerAction(status) {
      return new Promise((resolve, reject) => {
        AxiosInstance.post(`/payment-plans/acceptOrReject`, {
          status: status,
          offer_id: this.paymentPlan.offer.id,
          lead_id: this.paymentPlan.offer.lead_id,
          payment_plan_id: this.paymentPlan.id,
          user_id: this.currentUser.id,
          installment_amount: this.paymentPlan.per_installment_payment,
        })
          .then((response) => {
            if (response) {
              this.result = status;
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    showRejectedModal() {
      const params = {
        title: "İşlem İptali",
        text: "İşleminizi red etmek üzeresiniz. Bu işlemin geri dönüşü olamayacağından dolayı öncelikle bizimle irtibata geçmenizi tavsiye ederiz. İşlemi reddetmeye devam etmek istiyor musunuz? ",
        onConfirm: () => {
          return this.offerAction("rejected");
        },
      };
      this.$approvalModal.show(params);
    },
    paymentPlanId() {
      return this.$route.params.id;
    },
    isChoiceAvailable() {
      const status = this.paymentPlan?.offer?.offer_status;
      if (status == "closed" || status == "rejected") {
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    agreementUrl() {
      return (
        process.env.VUE_APP_BASE_URL + this.paymentPlan?.offer?.document?.path
      );
    },
    preInformationUrl() {
      return (
        process.env.VUE_APP_BASE_URL +
        this.paymentPlan?.offer?.information?.path
      );
    },
    trainingsHeader() {
      const headers = ["Eğitim", "Eğitim Fiyatı", "Eğitim İndirimli Fiyatı"];
      if (this.disableDiscountedPriceColumn) {
        headers.pop();
      }
      return headers;
    },
  },
};
</script>

<style></style>
